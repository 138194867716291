import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PipelineVideo from "../videos/pipeline.mp4";
import ApproveVideo from "../videos/approve-event.mp4";
import ExcludeVideo from "../videos/exclude-machine.mp4";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "scheduling-patching-plan"
    }}>{`Scheduling patching plan`}</h1>
    <p>{`AutoPatcher has it's own scheduler implemented. Plan contains a group of machines to update, time window in cron format and additional settings like pre and post hooks.`}</p>
    <p>{`There are two ways to schedule a patching plan: `}<strong parentName="p">{`simple plan`}</strong>{` and `}<strong parentName="p">{`patching pipeline`}</strong>{`. Both are described below in detail.`}</p>
    <h2 {...{
      "id": "scheduling-a-simple-patching-plan"
    }}>{`Scheduling a simple patching plan`}</h2>
    <h3 {...{
      "id": "step-1"
    }}>{`Step 1`}</h3>
    <p>{`In the User interface select from left navigation bar go to `}<strong parentName="p">{`Plans`}</strong>{` > `}<strong parentName="p">{`New Plan`}</strong></p>
    <p><img alt="step-1" src={require("../assets/patching-schedule/step-1.png")} /></p>
    <h3 {...{
      "id": "step-2"
    }}>{`Step 2`}</h3>
    <h4 {...{
      "id": "step-21"
    }}>{`Step 2.1`}</h4>
    <p>{`Choose the plan name`}</p>
    <h4 {...{
      "id": "step-22"
    }}>{`Step 2.2`}</h4>
    <p>{`Specify the time and frequency of plan execution via cron schedule using one of the two ways described below.`}</p>
    <p><strong parentName="p">{`Please note that all dates/times in AutoPatcher are in UTC`}</strong></p>
    <h5 {...{
      "id": "graphical-cron-editor"
    }}>{`Graphical cron editor`}</h5>
    <p><img alt="step-2-2a-1" src={require("../assets/patching-schedule/step-2-2a-1-edit-button.png")} />{`
`}<img alt="step-2-2a-2" src={require("../assets/patching-schedule/step-2-2a-2-cron-gui.png")} /></p>
    <h5 {...{
      "id": "manually-enter-cron-schedule"
    }}>{`Manually enter cron schedule`}</h5>
    <p><img alt="step-2-2b" src={require("../assets/patching-schedule/step-2-2b-cron-manual.png")} /></p>
    <h4 {...{
      "id": "step-23"
    }}>{`Step 2.3`}</h4>
    <p>{`Specify patching window length in hours`}</p>
    <h3 {...{
      "id": "step-3"
    }}>{`Step 3`}</h3>
    <p>{`In this step two plan properties can be specified:`}</p>
    <ul>
      <li parentName="ul">{`Notification group(s) which allow to receive various patching related information. `}<a parentName="li" {...{
          "href": "/notifications"
        }}>{`Read more`}</a>{`.`}</li>
      <li parentName="ul">{`Machines to be patched in the plan. Based on the `}<em parentName="li">{`Machine adding method`}</em>{` there are two types of plans: `}<strong parentName="li">{`standard`}</strong>{` and `}<strong parentName="li">{`dynamic`}</strong>{`, both of which are described below in detail.`}</li>
    </ul>
    <h4 {...{
      "id": "step-31-standard-plan"
    }}>{`Step 3.1 (standard plan)`}</h4>
    <p>{`Choose `}<strong parentName="p">{`List`}</strong>{` machine adding method. A `}<strong parentName="p">{`Machines`}</strong>{` field will appear where you can start typing a name or an id of some previously added machine (`}<a parentName="p" {...{
        "href": "/adding-machine"
      }}>{`read more`}</a>{`) to add it to the plan.`}</p>
    <p><img alt="step-3-1" src={require("../assets/patching-schedule/step-3.png")} /></p>
    <h5 {...{
      "id": "machine-related-properties"
    }}>{`Machine related properties`}</h5>
    <p>{`After the machine is selected some properties related to it can be changed:`}</p>
    <ul>
      <li parentName="ul">{`Patching order of the machine in regard to other machines in the plan.`}</li>
      <li parentName="ul">{`Pre/Post host hooks which allow to perform custom actions before/after this machine is patched (`}<a parentName="li" {...{
          "href": "/hooks"
        }}>{`read more`}</a>{`).`}</li>
    </ul>
    <p><img alt="step-3-1-1" src={require("../assets/patching-schedule/step-4.png")} /></p>
    <p>{`Later these properties can be changed by clicking on the corresponding edit button:`}</p>
    <p><img alt="step-3-1-2" src={require("../assets/patching-schedule/modify-machine.png")} /></p>
    <h4 {...{
      "id": "step-32-dynamic-plan"
    }}>{`Step 3.2 (dynamic plan)`}</h4>
    <p>{`Choose `}<strong parentName="p">{`Dynamic`}</strong>{` machine adding method and click the `}<strong parentName="p">{`ADD NEW`}</strong>{` button.`}</p>
    <p><img alt="step-3-2-1" src={require("../assets/patching-schedule/dynamic.png")} /></p>
    <p>{`Here the following information can be provided:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Tag list`}</inlineCode>{` `}<strong parentName="li">{`[required]`}</strong>{`: AWS EC2 tags of the machines that should be patched when this plan executes.
Multiple tag keys can be provided, and for each tag key, multiple tag values can be specified.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`IAM Role`}</inlineCode>{` `}<strong parentName="li">{`[required]`}</strong>{`: An IAM role, which will be used by AutoPatcher to scan the machines based on provided tags.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Regions`}</inlineCode>{` `}<em parentName="li">{`(optional)`}</em>{`: A list of regions in which to scan for machines. By default, all available regions are included, some of them can be manually removed from the list.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Allow reboot`}</inlineCode>{` `}<em parentName="li">{`(optional)`}</em>{`: `}<a parentName="li" {...{
          "href": "/adding-machine#allow-reboot-checkbox-note"
        }}>{`Read more`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Pre host hooks`}</inlineCode>{` `}<em parentName="li">{`(optional)`}</em>{`: `}<a parentName="li" {...{
          "href": "/hooks"
        }}>{`Read more`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Post host hooks`}</inlineCode>{` `}<em parentName="li">{`(optional)`}</em>{`: `}<a parentName="li" {...{
          "href": "/hooks"
        }}>{`Read more`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Update existing`}</inlineCode>{` `}<em parentName="li">{`(optional)`}</em>{`: described in the `}<a parentName="li" {...{
          "href": "#dynamic-machines"
        }}>{`dynamic machines`}</a>{` section.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Include stopped`}</inlineCode>{` `}<em parentName="li">{`(optional)`}</em>{`: If set to true then stopped/stopping EC2 instances will be added to a plan.
`}<img alt="step-3-2-2" src={require("../assets/patching-schedule/tags.png")} /></li>
    </ul>
    <p>{`After the confirmation this information still can be changed by clicking at the tag name(s) near the `}<strong parentName="p">{`Machines Tag`}</strong>{` label.`}</p>
    <h5 {...{
      "id": "scanning-ec2-instances-by-tags"
    }}>{`Scanning EC2 instances by tags`}</h5>
    <p>{`Please note that when the dynamic plan has multiple key-value pairs specified in the `}<inlineCode parentName="p">{`Tag list`}</inlineCode>{` field, AutoPatcher
scans for each of them separately and then combines all the found machines in a single list.
In other words, it uses an implicit `}<inlineCode parentName="p">{`OR`}</inlineCode>{` logical operator for joining the tag conditions.`}</p>
    <p>{`Example:`}</p>
    <p>{`If the AWS account has the following EC2 instances:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tags`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`autopatcher-enable`}</inlineCode>{` = `}<inlineCode parentName="td">{`true`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`dev-1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`autopatcher-enable`}</inlineCode>{` = `}<inlineCode parentName="td">{`true`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`dev-2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`autopatcher-enable`}</inlineCode>{` = `}<inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`dev-1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`dev-2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`QA`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`Production`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`autopatcher-enable`}</inlineCode>{` = `}<inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`Production`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`and the tag list in the dynamic plan is defined in the following way:`}</p>
    <p><img alt="example-tag-list" src={require("../assets/patching-schedule/example-tag-list.png")} /></p>
    <p>{`AutoPatcher will include in the plan and patch the following machines:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`machine-1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`machine-2`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`machine-3`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`machine-4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`machine-5`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`machine-7`}</inlineCode></li>
    </ul>
    <p>{`Please visit the `}<a parentName="p" {...{
        "href": "/cli#defining-a-dynamic-plan"
      }}>{`Defining a dynamic plan`}</a>{` section on the `}<inlineCode parentName="p">{`Command line interface`}</inlineCode>{` page
for the detailed instructions on how to define a dynamic plan using more advanced mechanism of logical expressions
to overcome this limitation.`}</p>
    <h5 {...{
      "id": "dynamic-machines"
    }}>{`Dynamic machines`}</h5>
    <p>{`Each time a dynamic plan is executed AutoPatcher scans for machines based on provided tags. All discovered machines are then added to the AutoPatcher database and can be seen on the `}<a parentName="p" {...{
        "href": "https://autopatcher.nordcloudapp.com/machines"
      }}>{`machine list view`}</a>{` marked as `}<strong parentName="p">{`DYNAMIC`}</strong>{`:`}</p>
    <p><img alt="step-3-2-3" src={require("../assets/patching-schedule/dynamic-machines.png")} /></p>
    <p>{`If the dynamic machine already exists in AutoPatcher (e.g. it was added by previous execution of the same dynamic plan) its properties can be synchronized with new ones which were retrieved from EC2. If `}<inlineCode parentName="p">{`Update existing`}</inlineCode>{` checkbox was selected, the machine in AutoPatcher will be updated, otherwise it won't be changed.`}</p>
    <h3 {...{
      "id": "step-4"
    }}>{`Step 4`}</h3>
    <p>{`In this step you can:`}</p>
    <ul>
      <li parentName="ul">{`add `}<strong parentName="li">{`On hold`}</strong>{` start/end date (period of time when patching shouldn't happen)`}</li>
      <li parentName="ul">{`select `}<strong parentName="li">{`dry run`}</strong>{` (execute plan without updating machines and list available patches instead. This can be useful for testing Auto patcher. This option is available for Windows and Linux machines. By default this option is switched off.`}</li>
      <li parentName="ul">{`select `}<strong parentName="li">{`manual approval`}</strong>{` - `}<a parentName="li" {...{
          "href": "/patching-events"
        }}>{`patching events`}</a>{` from this plan will require to be manually approved before patching (events which were approved after their time window had ended are `}<strong parentName="li">{`NOT`}</strong>{` going to be executed)`}</li>
      <li parentName="ul">{`specify a number of machines to be patched in parallel`}</li>
      <li parentName="ul">{`add `}<a parentName="li" {...{
          "href": "/hooks"
        }}>{`pre and post hooks`}</a></li>
    </ul>
    <p><img alt="step-5" src={require("../assets/patching-schedule/step-5.png")} /></p>
    <h2 {...{
      "id": "scheduling-a-patching-pipeline"
    }}>{`Scheduling a patching pipeline`}</h2>
    <p>{`AutoPatcher has it's own patching pipeline scheduler. Whenever you want to schedule multiple patching plans with dependencies between them you can use a `}<a parentName="p" {...{
        "href": "https://autopatcher.nordcloudapp.com/pipelines"
      }}>{`patching pipeline`}</a>{`.`}</p>
    <h3 {...{
      "id": "how-to-configure-a-patching-pipeline"
    }}>{`How to configure a patching pipeline`}</h3>
    <video controls style={{
      width: "100%"
    }}>
    <source src={PipelineVideo} type="video/mp4" />
    </video>
    <h3 {...{
      "id": "patching-pipelines-description"
    }}>{`Patching pipelines description`}</h3>
    <p>{`A patching pipeline allows to schedule multiple plans with dependencies between them. Pipelines are divided into steps, each of which consists of one or more patching plans. All plans that belong to a step are executed in parallel and the step window length is considered as the window length of the longest plan of that step. Patching events are generated for each step separately. The step is considered finished once all the events of plans belonging to it are finished. Following steps can be executed once previous steps were finished, depending on policy defined for the step.`}</p>
    <p>{`Patching pipelines are designed to allow patching for situations where different sets of machines are to be patched on different times, but in some relation to each other, e.g. when an application consists of different environments. A simple example would be an application which consists of a `}<inlineCode parentName="p">{`development`}</inlineCode>{` environment, a `}<inlineCode parentName="p">{`staging`}</inlineCode>{` or `}<inlineCode parentName="p">{`preproduction`}</inlineCode>{` environment and a `}<inlineCode parentName="p">{`production`}</inlineCode>{` or `}<inlineCode parentName="p">{`live`}</inlineCode>{` environment. A patching pipeline will allow to automate a process in which `}<inlineCode parentName="p">{`staging`}</inlineCode>{` will be a week after the `}<inlineCode parentName="p">{`development`}</inlineCode>{` environment was patched, if that patching was successfull, and then patch the `}<inlineCode parentName="p">{`live`}</inlineCode>{` environment after another week, but only if a user confirmed that everything was patched successfully and the application works properly on the `}<inlineCode parentName="p">{`staging`}</inlineCode>{` environment.`}</p>
    <p>{`Additionally, patching pipelines have the possibility to ensure that a patching baseline is followed for different environments. For more information check `}<a parentName="p" {...{
        "href": "/baseline"
      }}>{`baseline patching`}</a>{`.`}</p>
    <h3 {...{
      "id": "pipeline-properties-description"
    }}>{`Pipeline properties description`}</h3>
    <h4 {...{
      "id": "cron-window-start"
    }}>{`Cron window start`}</h4>
    <p>{`The `}<strong parentName="p">{`Cron window start`}</strong>{` property applies to the first step in the pipeline, more precisely to all plans within the first step, and determines when the whole pipeline will start. This Cron expression has the same meaning as for simple plans.`}</p>
    <h4 {...{
      "id": "step-delay-2nd-and-following-steps-only"
    }}>{`Step delay (2nd and following steps only)`}</h4>
    <p>{`All steps after the first have `}<strong parentName="p">{`step delay`}</strong>{` property (in `}<strong parentName="p">{`minutes`}</strong>{`) which means how much time should pass between the moment all the events from the preceding steps are finished and the moment all the plans from the following step are started.`}</p>
    <p>{`There is no maximum patch delay. You can set it for example to 72h if you want to execute next plan 3 days later.`}</p>
    <h4 {...{
      "id": "patching-policy-2nd-and-following-steps-only"
    }}>{`Patching policy (2nd and following steps only)`}</h4>
    <p><strong parentName="p">{`Patching policy`}</strong>{` property allows to specify the behavior of the next step based on the status of the previous step.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Policy`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Success or approval`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If all events for plans from the previous step succeeded, the next one is executed automatically. Otherwise, manual approval is required for the next step to be executed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Approval required`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regardless of the status of the previous step the next step requires manual approval to be executed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Patch anyway`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regardless of the status of the previous step the next step is executed automatically.`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "how-to-approve-events"
    }}>{`How to approve events`}</h5>
    <video controls style={{
      width: "100%"
    }}>
    <source src={ApproveVideo} type="video/mp4" />
    </video>
    <h4 {...{
      "id": "run-immediately-2nd-and-following-steps-only"
    }}>{`Run immediately (2nd and following steps only)`}</h4>
    <p>{`Normally any step excluding the first starts executing `}<em parentName="p"><strong parentName="em">{`step delay`}</strong></em>{` minutes after the previous step window ends. If the step has the `}<strong parentName="p">{`run immediately`}</strong>{` property set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, it is executed immediately (within 5 minutes) after the last plan of the previous step has `}<em parentName="p">{`actually`}</em>{` ended (all the plans could end earlier than their specified time window indicates), but with the condition that `}<strong parentName="p">{`the patching policy allows it`}</strong>{`. If the next step requires manual approval, it is executed immediately after it is approved.`}</p>
    <h2 {...{
      "id": "advanced-features"
    }}>{`Advanced features`}</h2>
    <h3 {...{
      "id": "specifying-windows-update-categoryseverity"
    }}>{`Specifying Windows update category/severity`}</h3>
    <p>{`Since AutoPatcher version `}<inlineCode parentName="p">{`1.130`}</inlineCode>{` there is an ability to specify the `}<inlineCode parentName="p">{`Update categories`}</inlineCode>{` and `}<inlineCode parentName="p">{`Severity levels`}</inlineCode>{` for
Windows machines on a plan level (works for pipelines as well).`}</p>
    <h4 {...{
      "id": "allowed-values"
    }}>{`Allowed values`}</h4>
    <p>{`The available update categories are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Application`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Connectors`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`CriticalUpdates`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`DefinitionUpdates`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`DeveloperKits`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Drivers`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`FeaturePacks`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Guidance`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Microsoft`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`SecurityUpdates`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ServicePacks`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Tools`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`UpdateRollups`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Updates`}</inlineCode></li>
    </ul>
    <p>{`The available security levels are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Critical`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Important`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Low`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Moderate`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Unspecified`}</inlineCode></li>
    </ul>
    <p>{`Both categories and severities can be specified as a single value or a comma-separated list
(`}<strong parentName="p">{`no whitespace is allowed after comma`}</strong>{`).`}</p>
    <p>{`Some configuration examples are presented in the following subsections.`}</p>
    <h4 {...{
      "id": "plans"
    }}>{`Plans`}</h4>
    <h5 {...{
      "id": "ui"
    }}>{`UI`}</h5>
    <p><img alt="plan-update-category_1.png" src={require("../assets/patching-schedule/plan-update-category_1.png")} />{`
`}<img alt="plan-update-category_2.png" src={require("../assets/patching-schedule/plan-update-category_2.png")} />{`
`}<img alt="plan-update-category_3.png" src={require("../assets/patching-schedule/plan-update-category_3.png")} /></p>
    <h5 {...{
      "id": "cliapi"
    }}>{`CLI/API`}</h5>
    <p>{`Plan payload:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "Custom Windows update category",
  "cron_window_start": "0 0 31 2 *",
  "window_length": 1,
  "machines_tag": {
    "regions": ["eu-central-1"],
    "iam_role_list": [
      "arn:aws:iam::000000000000:role/example-role"
    ],
    "reboot_policy": "ALWAYS",
    "update_existing": true,
    "tag_condition": {
      "expression": "ap-plan=win22-prod & win-cat=some"
    }
  },
  "windows_update_category": "DefinitionUpdates,DeveloperKits,UpdateRollups"
}
`}</code></pre>
    <p>{`To create this plan in CLI save the payload into the file named `}<inlineCode parentName="p">{`plan.json`}</inlineCode>{` and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli create plan --file plan.json
`}</code></pre>
    <h4 {...{
      "id": "pipelines"
    }}>{`Pipelines`}</h4>
    <p><strong parentName="p">{`NOTE:`}</strong>{` the `}<inlineCode parentName="p">{`windows_update_category`}</inlineCode>{` and `}<inlineCode parentName="p">{`windows_update_severity`}</inlineCode>{` does not have any effect in baseline pipelines!`}</p>
    <h5 {...{
      "id": "ui-1"
    }}>{`UI`}</h5>
    <p><img alt="pipeline-update-category_1.png" src={require("../assets/patching-schedule/pipeline-update-category_1.png")} />{`
`}<img alt="pipeline-update-category_2.png" src={require("../assets/patching-schedule/pipeline-update-category_2.png")} />{`
`}<img alt="pipeline-update-category_3.png" src={require("../assets/patching-schedule/pipeline-update-category_3.png")} />{`
`}<img alt="pipeline-update-category_4.png" src={require("../assets/patching-schedule/pipeline-update-category_4.png")} /></p>
    <h5 {...{
      "id": "cliapi-1"
    }}>{`CLI/API`}</h5>
    <p>{`Pipeline payload:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "[UPD-CAT] main",
  "cron_window_start": "0 0 31 2 *",
  "steps": [
    {
      "plans": [
        {
          "name": "[UPD-CAT] pipeline: default updates",
          "window_length": 60,
          "machines_tag": {
            "regions": ["eu-central-1"],
            "iam_role_list": [
              "arn:aws:iam::000000000000:role/example-role"
            ],
            "reboot_policy": "ALWAYS",
            "update_existing": true,
            "tag_condition": {
              "expression": "ap-plan=win22-prod & win-cat=default"
            }
          }
        },
        {
          "name": "[UPD-CAT] pipeline: all updates",
          "window_length": 60,
          "machines_tag": {
            "regions": ["eu-central-1"],
            "iam_role_list": [
              "arn:aws:iam::000000000000:role/example-role"
            ],
            "reboot_policy": "ALWAYS",
            "update_existing": true,
            "tag_condition": {
              "expression": "ap-plan=win22-prod & win-cat=all"
            }
          },
          "windows_update_category": "Application,Connectors,CriticalUpdates,DefinitionUpdates,DeveloperKits,Drivers,FeaturePacks,Guidance,Microsoft,SecurityUpdates,ServicePacks,Tools,UpdateRollups,Updates"
        },
        {
          "name": "[UPD-CAT] pipeline: definition updates",
          "window_length": 60,
          "machines_tag": {
            "regions": ["eu-central-1"],
            "iam_role_list": [
              "arn:aws:iam::000000000000:role/example-role"
            ],
            "reboot_policy": "ALWAYS",
            "update_existing": true,
            "tag_condition": {
              "expression": "ap-plan=win22-prod & win-cat=definition"
            }
          },
          "windows_update_category": "DefinitionUpdates"
        }
      ]
    }
  ]
}
`}</code></pre>
    <p>{`To create this pipeline in CLI save the payload into the file named `}<inlineCode parentName="p">{`pipeline.json`}</inlineCode>{` and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli create pipeline --file pipeline.json
`}</code></pre>
    <h4 {...{
      "id": "precedence"
    }}>{`Precedence`}</h4>
    <p>{`The update category and/or severity defined on a plan level takes precedence over the the same settings defined for a machine.`}</p>
    <p>{`For example if the machine presented below
`}<img alt="precedence-update-category-machine.png" src={require("../assets/patching-schedule/precedence-update-category-machine.png")} />{`
is added to the plan as shown below
`}<img alt="precedence-update-category-plan.png" src={require("../assets/patching-schedule/precedence-update-category-plan.png")} /></p>
    <p>{`the patching operation will only install `}<inlineCode parentName="p">{`DefinitionUpdates`}</inlineCode>{` on the machine.`}</p>
    <h3 {...{
      "id": "custom-patching-script"
    }}>{`Custom patching script`}</h3>
    <p>{`By default AutoPatcher performs patching action using the OS's default package manager. The details for each supported OS type is described below.`}</p>
    <h4 {...{
      "id": "linux"
    }}>{`Linux`}</h4>
    <p>{`On Linux machines AutoPatcher invokes a predefined shell command which is different for each type of distribution.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`APT-based (Ubuntu, Debian, etc.)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`export DEBIAN_FRONTEND=noninteractive
export UCF_FORCE_CONFFNEW=1
apt-get update && apt-get -o Dpkg::Options::=--force-confdef -o Dpkg::Options::=--force-confnew -y upgrade
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`YUM-based (Amazon Linux, Red Hat, CentOS, etc.)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yum update -y
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Zypper-based (OpenSUSE, SUSE Linux Enterprise Server)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`zypper refresh
zypper update -y
`}</code></pre>
      </li>
    </ul>
    <h4 {...{
      "id": "windows"
    }}>{`Windows`}</h4>
    <p>{`For patching Windows machines AutoPatcher uses a slightly modified version of the AWS default `}<inlineCode parentName="p">{`AWS-InstallWindowsUpdates`}</inlineCode>{` SSM document.`}</p>
    <h4 {...{
      "id": "custom"
    }}>{`Custom`}</h4>
    <p>{`When the special case requires a custom patching action, it can be defined in the form of a script (`}<em parentName="p">{`shell`}</em>{` script for Linux and `}<em parentName="p">{`Power Shell`}</em>{` for Windows). The script has to be uploaded to S3 and be accessible by AutoPatcher AWS account (`}<inlineCode parentName="p">{`286863837419`}</inlineCode>{`). The URL of the uploaded script can later be specified in three different ways each of which is described below.`}</p>
    <p>{`Note: AutoPatcher supports `}<inlineCode parentName="p">{`s3://`}</inlineCode>{`-style as well as `}<inlineCode parentName="p">{`https://`}</inlineCode>{`-style (both path-style and virtual hosted-style, `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/AmazonS3/latest/dev/VirtualHosting.html"
      }}>{`read more`}</a>{`) S3 URLs.`}</p>
    <h5 {...{
      "id": "per-machine-in-plan"
    }}>{`Per machine in plan`}</h5>
    <p><img alt="custom-script-per-plan-machine" src={require("../assets/patching-schedule/custom-script-per-plan-machine.png")} /></p>
    <p>{`Applies to the specific machine in the specific plan. Overrides all other custom script settings.`}</p>
    <h5 {...{
      "id": "per-machine"
    }}>{`Per machine`}</h5>
    <p><img alt="custom-script-per-machine" src={require("../assets/patching-schedule/custom-script-per-machine.png")} /></p>
    <p>{`Applies to the specific machine in all plans. Overrides per-plan custom script settings.`}</p>
    <h5 {...{
      "id": "per-plan"
    }}>{`Per plan`}</h5>
    <p><img alt="custom-script-per-plan" src={require("../assets/patching-schedule/custom-script-per-plan.png")} /></p>
    <p>{`Applies to all machines in the specific plan.`}</p>
    <h3 {...{
      "id": "excluding-machines-from-the-plan"
    }}>{`Excluding machines from the plan`}</h3>
    <p>{`Below you can see how to exclude machines from being patched in the plan. When you change the plan, all events generated before from this plan will be deleted and the new one will be created.`}</p>
    <p><strong parentName="p">{`Plan`}</strong>{` → `}<strong parentName="p">{`Edit plan`}</strong>{` → `}<strong parentName="p">{`Next step`}</strong>{` → `}<strong parentName="p">{`edit machine`}</strong>{` → `}<strong parentName="p">{`exclude machine`}</strong>{` → `}<strong parentName="p">{`save plan`}</strong></p>
    <video controls style={{
      width: "100%"
    }}>
    <source src={ExcludeVideo} type="video/mp4" />
    </video>
    <h3 {...{
      "id": "force-patching"
    }}>{`Force patching`}</h3>
    <p>{`Force patching means to execute a plan immediately (out of the defined schedule). It can be done by pressing a button shown on the image below.`}</p>
    <p><img alt="force-plan" src={require("../assets/patching-schedule/force-plan.png")} /></p>
    <p>{`In this case a single `}<a parentName="p" {...{
        "href": "/patching-events"
      }}>{`patching event`}</a>{` is created and scheduled to execute within 5 minutes after the force patching button was clicked.`}</p>
    <h4 {...{
      "id": "pipeline-force-patching"
    }}>{`Pipeline force patching`}</h4>
    <p>{`A pipeline can be executed in the similar fashion.`}</p>
    <p><img alt="force-pipeline" src={require("../assets/patching-schedule/force-pipeline.png")} /></p>
    <h3 {...{
      "id": "move-existing-plan-into-pipeline"
    }}>{`Move existing plan into pipeline`}</h3>
    <p>{`For existing plans there is an option to move them into pipelines. To do that,
in the User interface go from left navigation bar to `}<strong parentName="p">{`Plan pipelines`}</strong>{` and
select destination pipeline. Then click `}<strong parentName="p">{`Move plan`}</strong>{` button and you should be
able to choose existing plan and move it between steps. There is an option to
append new plan to existing step or insert it as a new step. The latter requires
`}<em parentName="p">{`step delay`}</em>{` input which will show accordingly to input. In a special case when
the plan is inserted at the very beginning of the pipeline, the `}<em parentName="p">{`step delay`}</em>{`
will be applied to the old first step, which in effect of the plan movement will
become second.`}</p>
    <h3 {...{
      "id": "install-only-security-updates-on-linux-hosts"
    }}>{`Install only security updates on Linux hosts`}</h3>
    <p>{`While defining a plan, you can select a setting which will tell AutoPatcher to install only security updates on all Linux machines added to the plan.`}</p>
    <p><img alt="security-updates" src={require("../assets/patching-schedule/security-updates-linux-only.png")} /></p>
    <p>{`If there are Windows machines included in the plan, this setting will have no effect for them.`}</p>
    <p>{`Older versions of Red Hat and CentOS require additional Yum plugin. Without it, the patching with this checkbox selected will fail. More information about the plugin available `}<a parentName="p" {...{
        "href": "https://access.redhat.com/solutions/10021"
      }}>{`here`}</a>{` (Red Hat) and `}<a parentName="p" {...{
        "href": "https://alvinalexander.com/linux-unix/centos-yum-installing-only-security-updates/"
      }}>{`here`}</a>{` (CentOS)`}</p>
    <h3 {...{
      "id": "reboot-policy-for-plan-or-pipeline"
    }}>{`Reboot policy for plan or pipeline`}</h3>
    <p>{`Since the version `}<inlineCode parentName="p">{`v1.135`}</inlineCode>{` of AutoPatcher, it is possible to set the `}<strong parentName="p">{`Reboot policy`}</strong>{` for a plan, a standalone and inside a pipeline as well.
If defined, it will override the `}<inlineCode parentName="p">{`reboot_policy`}</inlineCode>{` and legacy `}<inlineCode parentName="p">{`allow_reboot`}</inlineCode>{` settings for machines belonging to that plan,
but only for patching inside this plan.`}</p>
    <h4 {...{
      "id": "ui-2"
    }}>{`UI`}</h4>
    <h5 {...{
      "id": "ui-defining-reboot-policy-for-a-plan"
    }}>{`[UI]`}{` Defining reboot policy for a plan`}</h5>
    <p><img alt="plan-reboot-policy" src={require("../assets/patching-schedule/plan-reboot-policy.png")} /></p>
    <h5 {...{
      "id": "ui-defining-reboot-policy-for-a-plan-inside-a-pipeline"
    }}>{`[UI]`}{` Defining reboot policy for a plan inside a pipeline`}</h5>
    <p><img alt="plan-reboot-policy-pipeline" src={require("../assets/patching-schedule/plan-reboot-policy-pipeline.png")} /></p>
    <h4 {...{
      "id": "cli"
    }}>{`CLI`}</h4>
    <p>{`Please note, that in order to use this feature in CLI, you need to download the latest version of the CLI (at least `}<inlineCode parentName="p">{`v1.135`}</inlineCode>{`).`}</p>
    <h5 {...{
      "id": "cli-defining-reboot-policy-for-a-plan"
    }}>{`[CLI]`}{` Defining reboot policy for a plan`}</h5>
    <p>{`An example plan JSON definition to be used with `}<inlineCode parentName="p">{`nc-autopatcher-cli create plan`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "cron_window_start": "0 0 31 2 *",
  "window_length": 1,
  "name": "reboot-policy-test",
  "reboot_policy": "ALWAYS",
  "machines": [
    {
      "id": "7c5e29dc-57aa-462f-9792-eefb9070fa55",
      "name": "test"
    }
  ]
}
`}</code></pre>
    <h5 {...{
      "id": "cli-defining-reboot-policy-for-a-plan-inside-a-pipeline"
    }}>{`[CLI]`}{` Defining reboot policy for a plan inside a pipeline`}</h5>
    <p>{`An example pipeline JSON definition to be used with `}<inlineCode parentName="p">{`nc-autopatcher-cli create pipeline`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "cron_window_start": "0 0 31 2 *",
  "name": "reboot-policy-test",
  "steps": [
    {
      "plans": [
        {
          "name": "[reboot-policy-test] No plan policy",
          "window_length": 60,
          "machines_tag": {
            "tag_condition": {
              "expression": "ap-stage=dev & step=1"
            },
            "iam_role_list": [
              "arn:aws:iam::000000000000:role/AutoPatcher-ServiceRole"
            ],
            "regions": ["eu-central-1"],
            "reboot_policy": "IF_NEEDED"
          }
        }
      ]
    },
    {
      "step_delay": 0,
      "run_immediately": true,
      "plans": [
        {
          "name": "[reboot-policy-test] Plan policy NEVER",
          "window_length": 60,
          "reboot_policy": "NEVER",
          "machines_tag": {
            "tag_condition": {
              "expression": "ap-stage=dev"
            },
            "iam_role_list": [
              "arn:aws:iam::000000000000:role/AutoPatcher-ServiceRole"
            ],
            "regions": ["eu-central-1"]
          }
        }
      ]
    }
  ]
}
`}</code></pre>
    <h2 {...{
      "id": "important-information"
    }}>{`Important information`}</h2>
    <ul>
      <li parentName="ul">{`The maximum patching window duration is 24h`}</li>
      <li parentName="ul">{`Cron window minimum period is 3h (you can't create a plan which would run more frequently)`}</li>
      <li parentName="ul">{`When instance patching takes more time that time window length - it will result with the `}<inlineCode parentName="li">{`TimedOut`}</inlineCode>{` status. In this case we advice to check patching manually and increase time window if it's possible.`}</li>
      <li parentName="ul">{`5 minutes after a plan is created or updated you should be able to see the `}<a parentName="li" {...{
          "href": "/patching-events"
        }}>{`events`}</a>{` generated for the next 30 days.`}</li>
      <li parentName="ul">{`In case a plan is updated all the old events coming from the plan with status `}<inlineCode parentName="li">{`UPCOMING`}</inlineCode>{` are removed and replaced by newly generated events.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      